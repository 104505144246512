import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class Language extends Model {
    endpoint = 'languages';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
    ];

    clone = () => Language;
}

import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class Complexion extends Model {
    endpoint = 'complexions';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
    ]

    clone = () => Complexion;
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/modules/DashboardView.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
        // action: ['VIEW_INDIVIDUAL_DASHBOARD', 'VIEW_GENERAL_DASHBOARD'],
      },
    },

    {
      path: '/model-agents',
      name: 'modelAgentsView',
      component: () => import('@/views/modules/ModelAgentsView.vue'),
      meta: {
        pageTitle: 'Talentos',
        breadcrumb: [
          {
            text: 'Talentos',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    /**
       * Catálogos
       */
    {
      path: '/abilities',
      name: 'abilitiesView',
      component: () => import('@/views/modules/AbilitiesView.vue'),
      meta: {
        pageTitle: 'Habilidades',
        breadcrumb: [
          {
            text: 'Habilidades',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/complexions',
      name: 'complexionsView',
      component: () => import('@/views/modules/ComplexionsView.vue'),
      meta: {
        pageTitle: 'Complexiones',
        breadcrumb: [
          {
            text: 'Complexiones',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/destinations',
      name: 'destinationsView',
      component: () => import('@/views/modules/DestinationsView.vue'),
      meta: {
        pageTitle: 'Destinos',
        breadcrumb: [
          {
            text: 'Destinos',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    /**
       * Configuración
       */

    {
      path: '/model-agent-subscription-plans',
      name: 'modelAgentSubscriptionPlansView',
      component: () => import('@/views/modules/AgentModelSubscriptionPlansView.vue'),
      meta: {
        pageTitle: 'Planes de suscripción',
        breadcrumb: [
          {
            text: 'Planes de suscripción',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/model-agent-subscription-coupons',
      name: 'modelAgentSubscriptionCouponsView',
      component: () => import('@/views/modules/AgentModelSubscriptionCouponsView.vue'),
      meta: {
        pageTitle: 'Cupones de suscripción',
        breadcrumb: [
          {
            text: 'Cupones de suscripción',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    {
      path: '/event-types',
      name: 'eventTypesView',
      component: () => import('@/views/modules/EventTypesView.vue'),
      meta: {
        pageTitle: 'Tipos de eventos',
        breadcrumb: [
          {
            text: 'Tipos de eventos',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/languages',
      name: 'languagesView',
      component: () => import('@/views/modules/LanguagesView.vue'),
      meta: {
        pageTitle: 'Lenguajes',
        breadcrumb: [
          {
            text: 'Lenguajes',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    {
      path: '/model-types',
      name: 'modelTypesView',
      component: () => import('@/views/modules/ModelTypesView.vue'),
      meta: {
        pageTitle: 'Tipo de modelos',
        breadcrumb: [
          {
            text: 'Tipo de modelos',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    {
      path: '/prices',
      name: 'pricesView',
      component: () => import('@/views/modules/PricesView.vue'),
      meta: {
        pageTitle: 'Precios',
        breadcrumb: [
          {
            text: 'Precios',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    {
      path: '/users',
      name: 'usersView',
      component: () => import('@/views/modules/UsersView.vue'),
      meta: {
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true,
          },
        ],
        resource: 'Auth',
        action: 'read',
      },
    },

    /**
       * No auth routes
       */
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        resource: 'No-Auth',
        action: 'read',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

import Multimedia from '@/dc-it/models/Multimedia'
import MultipleRelation from '@/dc-it/types/MutipleRelation'
// import ModelAgentPhoto from '@/dc-it/models/ModelAgentPhoto'
import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'
import Relation from '@/dc-it/types/Relation'
import BooleanField from '@/dc-it/types/BooleanField'
import ModelAgentSubscription from '@/dc-it/models/ModelAgentSubscription'
import Complexion from '@/dc-it/models/Complexion'
import HTMLField from '@/dc-it/types/HTMLField'
import Ability from '@/dc-it/models/Ability'
import Language from '@/dc-it/models/Language'
import Destination from '@/dc-it/models/Destination'
import ModelType from '@/dc-it/models/ModelType'
import Country from '@/dc-it/models/Country'
import State from '@/dc-it/models/State'
import Select from '../types/Select'
import Field from '../types/Field'
import Model from './Model'

export default class ModelAgent extends Model {
    endpoint = 'model_agents';

    related = ['model_agent_photos', 'multimedia', 'complexion', 'current_subscription.model_agent_subscription_plan', 'abilities', 'languages', 'destinations', 'model_types', 'country', 'state'];

    status = {
      1: 'Verificado',
      0: 'Pendiente de verificación',
    };

    genders = {
      1: 'Femenino',
      2: 'Masculino',
    }

    tezColors = {
      'Piel pálida': 'Piel pálida',
      'Piel clara': 'Piel clara',
      'Piel morena clara': 'Piel morena clara',
      'Piel morena': 'Piel morena',
      'Piel bronceada': 'Piel bronceada',
      'Piel oscura': 'Piel oscura',
      'Piel negra': 'Piel negra',
    }

    eyeColors = {
      'Ojos azules': 'Ojos azules',
      'Ojos verdes': 'Ojos verdes',
      'Ojos marrones': 'Ojos marrones',
      'Ojos avellana': 'Ojos avellana',
      'Ojos grises': 'Ojos grises',
      'Ojos negros': 'Ojos negros',
      'Ojos almendrados': 'Ojos almendrados',
    }

    hairColors = {
      'Cabello rubio': 'Cabello rubio',
      'Cabello castaño': 'Cabello castaño',
      'Cabello negro': 'Cabello negro',
      'Cabello pelirrojo': 'Cabello pelirrojo',
      'Cabello pelirrojo oscuro': 'Cabello pelirrojo oscuro',
      'Cabello rubio platino': 'Cabello rubio platino',
      'Cabello gris': 'Cabello gris',
      'Cabello canoso': 'Cabello canoso',
      'Cabello blanco': 'Cabello blanco',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('first_name', 'Nombre'),
      new Field('last_name', 'Apellido'),
      new Field('birthday', 'Fecha de nacimiento', 'date').hide(),
      new Select('gender', 'Género', this.genders),
      new Field('email', 'Correo'),
      new Field('phone', 'Teléfono'),
      new Field('height', 'Altura', 'number').hide(),
      new Field('weight', 'Peso', 'number').hide(),
      new Select('skin_color', 'Color de tez', this.tezColors).hide(),
      new Select('hair_color', 'Color de cabello', this.hairColors).hide(),
      new Select('eyes_color', 'Color de ojos', this.eyeColors).hide(),
      new Field('measurement', 'Medidas').hide(),
      new HTMLField('description', 'Descripción').hide().setMode('textarea'),
      // new Relation('destination_id', 'Destino', Destination, 'name'),
      new MultipleMultimedia('multimedia', 'Multimedia', Multimedia).hide().setLimit(1),
      new Select('status', 'Estado', this.status).applyMask((value, field) => {
        let className = 'bg-danger'
        const button = `<model-agent-verify-modal :id="${this.getAttr('id')}" @verified="sendEvent('refresh')"/>`
        switch (field.originalValue) {
          case '0':
            className = 'bg-warning'

            break
          case '1':
            className = 'bg-success'
            break
          default:
            // className = 'text-bg-danger'
            break
        }
        const span = `<span class="badge ${className}">${value}</span>`
        return `<div>${span}${button}</div>`
      }).noFilterable(),
      new Field('account_number', 'Número de cuenta').hide(),
      new Field('bank_name', 'Banco').hide(),
      new MultipleRelation('model_agent_photos', 'Fotografías', Multimedia).hide(),
      new Relation('complexion', 'Complexión', Complexion, 'name').hide(),
      new BooleanField('with_tattoos', 'Tiene tatuajes').hide(),
      new Relation('current_subscription', 'Suscripción actual', ModelAgentSubscription, 'model_agent_subscription_plan').applyMask(() => '<manage-subscriptions-modal :currentSubscription=\'model.getAttr("current_subscription", false)\'/>').noFilterable(),
      new Relation('abilities', 'Habilidades', Ability, 'name').setMultiple().hide(),
      new Relation('languages', 'Lenguajes', Language, 'name').setMultiple().hide(),
      new Relation('destinations', 'Zonas de servicio', Destination, 'name').setMultiple().hide(),
      new Relation('model_types', 'Tipo de talento', ModelType, 'name').setMultiple().hide(),
      new Relation('country', 'Nacionalidad', Country, 'name').hide(),
      new Relation('state', 'Estado de residencia', State, 'name').hide(),

    ];

    clone = () => ModelAgent;
}

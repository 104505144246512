<template>
  <div
    :class="options.showLabel ? 'form-group': 'form-control'"
    style="display:block;"
  >
    <label v-if="options.showLabel">{{ options.label }}</label>
    <vue-editor
      v-if="field.mode ==='html' "
      v-model="field.value"
    />
    <textarea
      v-if="field.model='textarea'"
      v-model="field.value"
      :class="options.showLabel ? 'form-control' : 'without-label'"
      :placeholder="options.label"
      :disabled="!options.enabled"
      @change="onChange(field)"
    />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  name: 'HTMLInput',
  components: {
    VueEditor,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    onChange(field) {
      this.$emit('change', field)
    },
  },
}
</script>

<style>
textarea {
  resize: none;
  height: 150px !important;
}
</style>

<template>
  <div class="image-input-container">
    <input
      ref="image-picker"
      type="file"
      style="display: none;"
      @change="onChange(field)"
    >
    <div class="form-group">
      <div
        class="image-container"
        @click="selectImage"
      >
        <img
          v-if="field.fileUrl === ''"
          :src="placeholder"
          alt="placeholder"
        >
        <img
          v-if="field.fileUrl !== ''"
          :src="field.fileUrl"
          :alt="field.fileName"
        >
      </div>
      <label @click="selectImage">{{ options.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    options: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      placeholder: require('@/assets/images/icons/jpg.png'),
    }
  },
  methods: {
    onChange(field) {
      const el = this.$refs['image-picker']
      if (el.files && el.files[0]) {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of el.files) {
          const reader = new FileReader()
          const { name } = file
          reader.onload = element => {
            field.setValue({
              edited: true,
              file_url: element.target.result,
              filename: name,
            })
            this.$emit('change', field)
          }

          if ((/(.jpg|.jpeg|.png|.gif)$/i).exec(name)) {
            reader.readAsDataURL(file)
          } else {
            // eslint-disable-next-line
            alert(`El archivo ${file.name} no se pudo agregar, ya que, no es una imagen`)
          }
        }
      }
    },
    selectImage() {
      this.$refs['image-picker'].click()
    },
  },
}
</script>

<style scoped>
    .image-container {
        display: flex;
        margin: auto;
        background: #c2c3c48a;
        border-radius: 100%;
        width: 120px;
        height: 120px;
        overflow: hidden;
        cursor: pointer;
    }

    .image-container img {
        display: block;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
    }

    label {
        text-align: center;
        display: block;
        cursor: pointer;
    }
</style>

import Model from '@/dc-it/models/Model'
import Image from '@/dc-it/types/Image'
import Select from '@/dc-it/types/Select'
import Field from '@/dc-it/types/Field'

export default class ModelType extends Model {
    endpoint = 'model-types';

    related = ['multimedia'];

    genders = {
      // (0=ambos, 1=mujer, 2=hombre
      0: 'Ambos',
      1: 'Mujer',
      2: 'Hombre',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Image('multimedia', 'Icono', 'multimedia'),
      new Field('name', 'Nombre'),
      new Select('gender', 'Género', this.genders, '0'),
    ];

    clone = () => ModelType;
}

import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import helpers from '@/dc-it/services/helpers'
import Select from '@/dc-it/types/Select'

export default class ModelAgentSubscriptionPlan extends Model {
    endpoint = 'model_agent_subscription_plans';

    statuses = {
      0: 'Cancelado',
      1: 'Pendiente de pago',
      2: 'Activo',
    };

    intervals = {
      day: 'Diario',
      week: 'Semanal',
      month: 'Mensual',
      year: 'Anual',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new Field('price', 'Precio', 'number').applyMask(value => `$${helpers.numberFormat(value)}`),
      // new Field('duration', 'Duración (en días)', 'number', 365).applySuffix(' días'),
      // new Field('duration_label', 'Etiqueta de duración'),
      new Field('interval_count', 'Cantidad de intervalos', 'number'),
      new Select('interval', 'Intervalo', this.intervals),
      new Field('trial_period_days', 'Días de prueba', 'number').applySuffix(' días'),
    ];

    clone = () => ModelAgentSubscriptionPlan;
}

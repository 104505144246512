import Field from './Field'
import HTMLInput from '../components/inputs/HTMLInput.vue'

export default class HTMLField extends Field {
    mode ='html'

  getComponent = () => HTMLInput;

  setMode(mode) {
    this.mode = mode
    return this
  }
}

import Select from '@/dc-it/types/Select'
import Model from './Model'
import Field from '../types/Field'

export default class Multimedia extends Model {
    endpoint = 'multimedia';

    status = {
      1: 'Verificado',
      0: 'Pendiente de verificación',
    };

    fields = [
      new Field('id', 'Código', 'number'),
      new Field('filename', 'Texto alterno'),
      new Field('file_url', 'URL de Acceso'),
      new Select('status', 'Estado', this.status),
    ];

    clone = () => Multimedia;
}

import Image from '@/dc-it/types/Image'
import HTMLField from '@/dc-it/types/HTMLField'
import Model from './Model'
import Field from '../types/Field'

export default class Destination extends Model {
    endpoint = 'destinations';

    related = ['multimedia'];

    fields = [
      new Field('id', 'Código').disabled(),
      new Image('multimedia', 'Multimedia', 'multimedia'),
      new Field('name', 'Nombre'),
      new HTMLField('description', 'Descripción').hide(),
    ];

    clone = () => Destination;
}
